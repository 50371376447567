<template>
  <div class="game-container">
    <StrokePath
      :firstAnimalInfo="firstAnimalInfo"
      :secondAnimalInfo="secondAnimalInfo"
      :puzzleBgImg="puzzleBgImg"
      :buttonList="buttonList"
      :puzzleItemList="puzzleItemList"
      :strokeStepsArr="strokeStepsArr"
      @avatarMove="handleMove"
      @getTarget="handleGetTarget"
      @firstStep="handleFirstStep"
      @initGame="handleInit"
      :lessonNum="lessonNum"
    />
  </div>
</template>

<script>
import StrokePath from "@/components/Course/GamePage/StrokePathGame";
export default {
  data() {
    return {
      lessonNum: 4,
      strokeStepsArr: [7, 7],
      puzzleItemList: [
        [
          { type: 0, isShow: false, index: 1 },
          { type: 1, isShow: false, index: 1 },
          { type: 1, isShow: false, index: 2 },
          { type: 0, isShow: false, index: null },
        ],
        [
          { type: 0, isShow: false, index: 2 },
          { type: 0, isShow: false, index: 3 },
          { type: 1, isShow: false, index: 3 },
          { type: 1, isShow: false, index: 4 },
        ],
        [
          { type: 1, isShow: false, index: null },
          { type: 0, isShow: false, index: 4 },
          { type: 0, isShow: false, index: 5 },
          { type: 1, isShow: false, index: 5 },
        ],
        [
          { type: 1, isShow: false, index: null },
          { type: 1, isShow: false, index: null },
          { type: 0, isShow: false, index: 6 },
          { type: 1, isShow: false, index: 6 },
        ],
        [
          { type: 0, isShow: false, index: null },
          { type: 1, isShow: false, index: null },
          { type: 0, isShow: false, index: 7 },
          { type: 1, isShow: false, index: 7 },
        ],
      ],
      puzzleBgImg: require("@/assets/img/16-GAMES/G18-maze2/lesson-04/background.svg"),
      secondAnimalInfo: {
        img: require("@/assets/img/16-GAMES/G18-maze2/lesson-04/maze-monkey.svg"),
        finalImg: require("@/assets/img/16-GAMES/G18-maze2/lesson-04/maze-monkey-food.svg"),
        secondStrokeImgList: this.firstStrokeImgList(),
      },
      firstAnimalInfo: {
        img: require("@/assets/img/16-GAMES/G18-maze2/lesson-04/maze-panda.svg"),
        finalImg: require("@/assets/img/16-GAMES/G18-maze2/lesson-04/maze-panda-food.svg"),
        firstStrokeImgList: this.secondStrokeImgList(),
      },
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G18-maze2/lesson-04/maze-panda.svg"),
          isPuzzlePage: true,
          startNumberRange: 1,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G18-maze2/lesson-04/maze-monkey.svg"),
          isPuzzlePage: true,
          startNumberRange: 2,
          endNumberRange: 3,
        },
      ],
    };
  },
  components: {
    StrokePath,
  },
  methods: {
    firstStrokeImgList() {
      let imgArr = [];
      for (let i = 1; i <= 7; i++) {
        imgArr.push(
          require(`@/assets/img/16-GAMES/G18-maze2/lesson-04/hengzhe-${i}.svg`)
        );
      }
      return imgArr;
    },
    secondStrokeImgList() {
      let imgArr = [];
      for (let i = 1; i <= 7; i++) {
        imgArr.push(
          require(`@/assets/img/16-GAMES/G18-maze2/lesson-04/heng-${i}.svg`)
        );
      }
      return imgArr;
    },
    handleMove(indexObj) {
      const { lastRowIndex, lastColIndex, currentRowIndex, currentColIndex } =
        indexObj;
      console.log(lastRowIndex, lastColIndex, currentRowIndex, currentColIndex);
      this.puzzleItemList[lastRowIndex][lastColIndex].isShow =
        !this.puzzleItemList[lastRowIndex][lastColIndex].isShow;
      this.puzzleItemList[currentRowIndex][currentColIndex].isShow =
        !this.puzzleItemList[currentRowIndex][currentColIndex].isShow;
    },
    handleFirstStep(index) {
      if (!index) {
        this.puzzleItemList[0][0].isShow = true;
      } else {
        this.puzzleItemList[0][1].isShow = true;
      }
    },
    handleGetTarget(obj) {
      const { currentRowIndex: row, currentColIndex: col } = obj;
      console.log(row, col);
      this.puzzleItemList[row][col].isGetTarget = true;
    },
    handleInit() {
      this.puzzleItemList.forEach((item) => {
        item.forEach((ele) => {
          ele.isShow = false;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>