<template>
  <div class="game-container">
    <PhoneNumberGame
      :callList="callList"
    />
  </div>
</template>

<script>
import PhoneNumberGame from "@/components/Course/GamePage/PhoneNumberGame";
export default {
  data() {
    return {
      callList: [
        {
          id: 1,
          image: '',
          sound: require("@/assets/audio/L1/6-Numbers/2-1-3.mp3"),
          number: "213",
          pinying: "èr     yī    sān",
          hanzi: "二一三",
          show: true,
        },
        {
          id: 2,
          image: '',
          sound: require("@/assets/audio/L1/6-Numbers/5-3-1-4.mp3"),
          number: "5314",
          pinying: "wǔ   sān   yī     sì ",
          hanzi: "五三一四",
          show: true,
          hasDialButton: false //this background (safe) has no "dial" button. Every keypress will submit
        },
        {
          id: 3,
          image: '',
          sound: require("@/assets/audio/L1/6-Numbers/2-5-1-3-4.mp3"),
          number: "25134",
          pinying: "  èr    wǔ    yī    sān    sì ",
          hanzi: "二五一三四",
          show: true,
          hasDialButton: false //this background (door) has no "dial" button. Every keypress will submit
        },
      ],
    };
  },
  components: {
    PhoneNumberGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











