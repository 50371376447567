<template>
  <div class="customlesson layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @click.native="
        {
          numBers != 4 ? playClick() : null;
        }
      "
    ></PageButton>
    <div class="customlesson-content">
      <div class="correct-button" v-if="correctShow">
        <img
          src="@/assets/img/16-GAMES/G18-maze2/background-controls.svg"
          alt=""
        />
        <div class="correct-button-content">
          <img
            v-if="correctOne == 1"
            src="@/assets/img/16-GAMES/G14-tape-recorder/wrong-answer.svg"
            alt=""
          />
          <img
            v-if="correctOne == 2"
            src="@/assets/img/16-GAMES/G14-tape-recorder/wrong-answer-hover.svg"
            alt=""
          />
          <img
            v-if="correctTwo == 1"
            src="@/assets/img/16-GAMES/G14-tape-recorder/correct-answer.svg"
            alt=""
          />
          <img
            v-if="correctTwo == 2"
            src="@/assets/img/16-GAMES/G14-tape-recorder/correct-answer-hover.svg"
            alt=""
          />
        </div>
      </div>
      <div v-if="display" class="mask-book">
        <div class="mask-book-content">
          <div
            class="content-bottom-panda"
            v-if="buttonHover == 3 && numBers != 6"
          >
            <img src="@/assets/img/05-Panda/panda-correct.gif" alt="" />
          </div>
          <div class="customlesson-content-text" @click.stop="activeClick(1)">
            <span
              class="lesson_one"
              :class="{ active: 'activeType === 1' }"
              @click.stop="activeClick"
            >
              <img class="volume-button" :src="volumeImgSrc" alt="" />
            </span>
            <span v-if="numBers == 2 || numBers == 6" class="font30 pinyin"
              >Nǐ hǎo,wǒ shì Dōngdōng.</span
            >
            <span
              v-if="numBers == 2 || numBers == 6"
              class="font3"
              id="facesOne"
              >你好，我是冬冬。</span
            >
            <span v-if="numBers == 4" class="font-pinyin-medium pinyin">Nǐ hǎo</span>
            <span v-if="numBers == 4" class="font-hanzi-medium" id="facesOne">你好</span>
          </div>
          <div class="frequency"></div>
          <div class="content-bottom">
            <div class="time-display" v-if="buttonHover > 1">
              <span>00:04</span>
            </div>
            <div
              class="content-button"
              @mouseover="buttonOver(1)"
              @mouseleave="buttonLeave(1)"
              @click.stop="buttonClick(1)"
            >
              <img
                v-if="buttonShow == 1"
                src="@/assets/img/16-GAMES/G14-tape-recorder/record.svg"
                alt=""
              />
              <img
                v-if="buttonShow == 2"
                src="@/assets/img/16-GAMES/G14-tape-recorder/record-hover.svg"
                alt=""
              />
            </div>
            <div
              class="content-button-headset"
              v-if="buttonHover > 1"
              @mouseover="buttonOver(2)"
              @mouseleave="buttonLeave(2)"
              @click.stop="buttonClick(2)"
            >
              <img
                v-if="headsetShow == 1"
                src="@/assets/img/16-GAMES/G14-tape-recorder/listen.svg"
                alt=""
              />
              <img
                v-if="headsetShow == 2"
                src="@/assets/img/16-GAMES/G14-tape-recorder/listen-hover.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="content-text">
        <div class="customlesson-content-text" @click.stop="activeClick(1)">
          <span class="font-pinyin-medium pinyin">Wǒ yǒu yí ge mèimei</span>
          <span class="font-hanzi-medium" id="facesOne">我有一个妹妹。</span>
        </div>
      </div>
      <div class="red-background" v-if="numBers == 1">
        <div class="horn"></div>
        <span class="font-pinyin-medium pinyin">Nǐ hǎo, wǒ shì Dōngdōng.</span>
        <span class="font-hanzi-medium" id="facesOne">你好，我是冬冬。</span>
      </div>
      <div class="red-answer" v-if="numBers == 3 || numBers == 5">
        <img src="@/assets/img/16-GAMES/G12-map/bubble-nihao.svg" alt="" />
      </div>
      <div class="blue-background" v-if="numBers == 1">
        <div class="horn"></div>
      </div>
      <div
        class="customlesson_bottom_left_img"
        v-if="numBers == 4"
        @mouseover="mouseover(1)"
        @mouseleave="mouseleave(1)"
        @click.stop="playClick(2)"
      >
        <img
          v-if="hoverClassOne == 1"
          src="@/assets/img/04-Buttons/button-next.svg"
          alt=""
        />
        <img
          v-if="hoverClassOne == 2"
          src="@/assets/img/04-Buttons/button-next-hover.svg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  props: ["knowSum"],
  components: {
    PageButton,
  },
  data() {
    return {
      numBers: 1,
      buttonShow: 1,
      display: false,
      hoverClassOne: 1,
      hoverClass: 1,
      buttonHover: 1,
      headsetHover: 1,
      correctOne: 1,
      correctTwo: 1,
      headsetShow: 1,
      numBersBloo: 0,
      correctShow: false,
      volumeImgSrc: require("@/assets/img/06-Sound/sound-white.svg"),
      nameOne: "你好！你好！我是冬冬。",
      bclickNum: 0,
      btype: "",
      pclickNum: 0,
      ptype: "",
      aclickNum: 0,
      atype: "",
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "play",
            startNumberRange: 1,
            endNumberRange: 3,
          },
        ],
      },
    };
  },
  watch: {
    // 发送
    bclickNum() {
      const data = {
        clickType: 3001402,
        data: { value: this.btype },
        text: "speakingGamePlay按钮socket",
      };
      this.sendSocketInfo = { ...data };
      this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
    },
    pclickNum() {
      const data = {
        clickType: 3001401,
        data: { value: this.ptype },
        text: "speakingGameButton按钮socket",
      };
      this.sendSocketInfo = { ...data };
      this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
    },
    aclickNum() {
      const data = {
        clickType: 3001403,
        data: { value: this.atype },
        text: "speakingGameRead按钮socket",
      };
      this.sendSocketInfo = { ...data };
      this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
    },
  },
  mounted() {
    this.$bus.$on("speakingGameButton", (val) => {
      this.playClick(val, 1);
    });
    this.$bus.$on("speakingGamePlay", (val) => {
      this.buttonClick(val, 1);
    });
    this.$bus.$on("speakingGameRead", (val) => {
      this.activeClick(val, 1);
    });
  },
  beforeDestroy() {
    this.$bus.$off("speakingGameButton");
    this.$bus.$off("speakingGamePlay");
    this.$bus.$off("speakingGameRead");
  },
  methods: {
    buttonOver(type) {
      if (this.numBers == 2) {
        if (type == 1) {
          this.buttonShow = 2;
        } else if (type == 2) {
          this.headsetShow = 2;
        }
      } else if (this.numBers == 4) {
        if (type == 1) {
          this.buttonShow = 2;
        } else if (type == 2) {
          this.headsetShow = 2;
        }
      }
    },
    buttonClick(type, atype) {
      if (atype != 1) {
        this.bclickNum++;
      }
      this.btype = type;
      if (this.numBers == 2) {
        if (type == 1) {
          this.buttonHover = 2;
        } else {
          this.headsetHover = 2;
          this.buttonHover = 3;
          this.buttonShow = 1;
        }
      } else if (this.numBers == 4) {
        if (type == 1) {
          this.buttonHover = 2;
        } else {
          this.headsetHover = 2;
          this.buttonHover = 3;
          this.buttonShow = 1;
        }
      } else if (this.numBers == 6) {
        if (type == 1) {
          this.buttonHover = 2;
        } else {
          this.correctShow = true;
          this.headsetHover = 2;
          this.buttonHover = 3;
          this.buttonShow = 1;
        }
      }
    },
    buttonLeave(type) {
      if (this.numBers == 2) {
        if (type == 1) {
          if (this.buttonHover == 1) {
            this.buttonShow = 1;
          } else {
            this.buttonShow = 2;
          }
        } else {
          if (this.headsetHover == 1) {
            this.headsetShow = 1;
          } else {
            this.headsetShow = 2;
          }
        }
      } else if (this.numBers == 4) {
        if (type == 1) {
          if (this.buttonHover == 1) {
            this.buttonShow = 1;
          } else {
            this.buttonShow = 2;
          }
        } else {
          if (this.headsetHover == 1) {
            this.headsetShow = 1;
          } else {
            this.headsetShow = 2;
          }
        }
      }
    },
    playClick(type, atype) {
      if (atype != 1) {
        this.pclickNum++;
      }
      this.ptype = type;
      if (type == 2) {
        this.numBers = 5;
        this.display = false;
        this.hoverClassOne = 1;
        this.headsetHover = 1;
        this.buttonHover = 1;
      } else {
        if (this.numBers == 1) {
          this.display = true;
          this.numBers = 2;
        } else if (this.numBers == 3) {
          this.display = true;
          this.numBers = 4;
          this.buttonHover = 1;
        } else if (this.numBers == 5) {
          this.display = true;
          this.buttonHover = 1;
          this.numBers = 6;
        }
      }
    },
    foseImgClick() {
      this.numBers = 4;
      this.numBersBloo = 1;
    },
    mouseover(type) {
      if (type == 1) {
        this.hoverClassOne = 2;
      } else {
        this.hoverClass = 2;
      }
    },
    mouseleave(type) {
      if (type == 1) {
        this.hoverClassOne = 1;
      } else {
        this.hoverClass = 1;
      }
    },
    numClick() {
      this.numBers += 1;
      if (this.numBers == 3) {
        this.display = false;
        this.hoverClassOne = 1;
        this.headsetHover = 1;
        this.buttonHover = 1;
      } else if (this.numBers == 5) {
        this.display = false;
        this.hoverClassOne = 1;
        this.headsetHover = 1;
        this.buttonHover = 1;
      } else if (this.numBers == 7) {
        this.$emit("knowSumberNine", this.knowSum);
      }
    },
    activeClick(type, atype) {
      if (atype != 1) {
        this.aclickNum++;
      }
      this.atype = type;
      let readWords = "";
      if (type == 1) {
        readWords = document.getElementById("facesOne").innerText;
      } else if (type == 2) {
        readWords = document.getElementById("facesTwo").innerText;
      } else if (type == 3) {
        readWords = document.getElementById("facesFree").innerText;
      } else if (type == 4) {
        readWords = document.getElementById("facesFrou").innerText;
      } else if (type == 5) {
        readWords = document.getElementById("facesFreo").innerText;
      }
      var url =
        "http://tts.baidu.com/text2audio?lan=zh&ie=UTF-8&text=" +
        encodeURI(readWords);
      var n = new Audio(url);
      n.src = url;
      n.play();
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
  },
};
</script>
<style scoped lang="scss">
.customlesson {
  .customlesson-content {
    width: 100%;
    height: 100%;
    background-color: #f7f4e0;
    background-image: url("../../../../../assets/img/13-Video/lesson-03-video-1.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 58px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .correct-button {
      width: 40%;
      position: absolute;
      bottom: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 6000;
      img {
        width: 100%;
      }
      .correct-button-content {
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 40%;
          padding: 10%;
        }
      }
    }
    .mask-book {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba($color: #000000, $alpha: 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2000;
      border-radius: 58px;
      .mask-book-content {
        width: 60%;
        height: 50%;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        background-color: #224e96;
        position: relative;
        .content-bottom-panda {
          width: 30%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          bottom: 0;
          right: 0;
          img {
            width: 100%;
          }
        }
        .customlesson-content-text {
          position: absolute;
          top: -10%;
          width: 70%;
          height: 20%;
          background-color: #ffffff;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          border-radius: 18px;
          padding: 5px 0;
          position: relative;
          .lesson_one {
            position: absolute;
            left: 5%;
            width: 50px;
            height: 32px;
            background-color: #224e96;
            border-radius: 50%;
            text-align: center;
            padding: 8px 0;
            .volume-button {
              cursor: pointer;
            }
            img {
              width: 30px;
              height: 30px;
            }
          }
          .lesson_one:hover {
            background-color: #1b4589;
          }
          span {
            color: #224e96;
            // font-family: "TheItisCpinyin-LIGHT";
          }
        }
        .frequency {
          border-top: 1px solid #fff;
          width: 100%;
          position: absolute;
          top: 45%;
          color: #fff;
        }
        .content-bottom {
          position: absolute;
          bottom: 10%;
          width: 60%;
          display: flex;
          justify-content: center;
          align-items: center;
          .content-button {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 50%;
            }
          }
          .content-button-headset {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 50%;
            }
          }
          .time-display {
            color: #fff;
          }
        }
      }
    }
    .red-background {
      width: 30%;
      height: 12%;
      background-color: #cd4c3f;
      position: absolute;
      top: 22%;
      left: 15%;
      border-radius: 40px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      padding: 18px 18px 23px 18px;
      z-index: 1001;
      span {
        color: #fff;
        white-space: nowrap;
        // font-family:"TheItisCpinyin-LIGHT";
      }
      .horn {
        width: 0;
        height: 0;
        border-top: 20px solid #cd4c3f;
        border-left: 20px solid transparent;
        position: absolute;
        bottom: -20px;
        right: 15%;
      }
    }
    .red-answer {
      position: absolute;
      top: 40%;
      left: 60%;
      img {
        width: 50%;
      }
    }
    .blue-background {
      width: 30%;
      height: 12%;
      background-color: #224e96;
      position: absolute;
      top: 23%;
      left: 15.7%;
      border-radius: 40px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      padding: 18px 18px 23px 18px;
      span {
        color: #fff;
      }
      .horn {
        width: 0;
        height: 0;
        border-top: 20px solid #224e96;
        border-left: 20px solid transparent;
        position: absolute;
        bottom: -20px;
        right: 15%;
      }
    }
    .content-text {
      width: 100%;
      height: 100%;
      position: relative;
      .customlesson-content-text {
        position: absolute;
        top: 3%;
        margin: auto;
        width: 70%;
        background-color: #224e96;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        border-radius: 58px;
        padding: 15px 0px;
        position: relative;
        margin-bottom: 5%;
        .lesson_one {
          position: absolute;
          left: 10%;
          width: 50px;
          height: 32px;
          background-color: #f4d737;
          border-radius: 50%;
          text-align: center;
          padding: 8px 0;
          .volume-button {
            cursor: pointer;
          }
          img {
            width: 30px;
            height: 30px;
          }
        }
        .lesson_one:hover {
          background-color: #1b4589;
        }
        span {
          color: #fff;
          // font-family: "TheItisCpinyin-LIGHT";
        }
      }
    }
    .custom_treeImg {
      min-width: 30%;
      min-height: 20%;
      background-color: #fff;
      border-radius: 58px;
      padding: 5% 5% 5% 10%;
      .customlesson-content-text {
        width: 100%;
        flex-flow: column;
        display: flex;
        justify-content: center;
        margin-bottom: 5%;
        // align-items:center;
        span {
          font-size: 30px;
        }
      }
    }
    .customlesson_bottom_left_img {
      position: absolute;
      bottom: -1%;
      right: 0;
      width: 22%;
      border-top-left-radius: 58px;
      text-align: center;
      z-index: 5555;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
  }
}
</style>
