<template>
  <div class="game-container layout-border">
    <FindStrokeTrain
      :targetStrokeImg="targetStrokeImg"
      :gameIndex="gameIndex"
      :hanziStrokeInfo="hanziStrokeInfo"
      :lightIndex="lightIndex"
      :strokePinyin="strokePinyin"
      @showStroke="handleShowStroke"
      :shadowImg="shadowImg"
      :headerBgImg="headerBgImg"
    />
  </div>
</template>

<script>
import FindStrokeTrain from "@/components/Course/GamePage/FindStrokeTrainGame";
export default {
  data() {
    return {
      headerBgImg: {
        defaultImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-04-1/0-ship.svg"),
        firstImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-04-1/1-ship.svg"),
        secondImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-04-1/2-ship.svg"),
        thirdImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-04-1/3-ship.svg"),
      },
      shadowImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-04-1/wave.svg"),
      targetStrokeImg: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-04/hengzhe-white.svg"),
      strokePinyin: "héngzhé",
      gameIndex: 2,
      lightIndex: [1, 2, 4],
      hanziStrokeInfo: [
        {
          pinyin: "kǒu",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-04-1/kou-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-04-1/kou-1.svg"),
              isShow: false,
              index: 1,
            },
          ],
        },
        {
          pinyin: "jiàn",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-04-1/jian-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-04-1/jian-1.svg"),
              isShow: false,
              index: 2,
            },
          ],
        },
        {
          pinyin: "yǎn",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-04-1/yan-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-04-1/yan-1.svg"),
              isShow: false,
              index: 3,
            },
            {
              bgImg: require("@/assets/img/16-GAMES/G16-dynamo-machine/lesson-04-1/yan-2.svg"),
              isShow: false,
              index: 4,
            },
          ],
        },
      ],
    };
  },
  components: {
    FindStrokeTrain,
  },
  methods: {
    handleShowStroke(index, indexSon) {
      this.hanziStrokeInfo[index].strokeFinishImgList[indexSon].isShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>