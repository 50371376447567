import { render, staticRenderFns } from "./18_RankMapPageTwo.vue?vue&type=template&id=109b7d22&scoped=true"
import script from "./18_RankMapPageTwo.vue?vue&type=script&lang=js"
export * from "./18_RankMapPageTwo.vue?vue&type=script&lang=js"
import style0 from "./18_RankMapPageTwo.vue?vue&type=style&index=0&id=109b7d22&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "109b7d22",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/nihaokidsNew-front_BmM9/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('109b7d22')) {
      api.createRecord('109b7d22', component.options)
    } else {
      api.reload('109b7d22', component.options)
    }
    module.hot.accept("./18_RankMapPageTwo.vue?vue&type=template&id=109b7d22&scoped=true", function () {
      api.rerender('109b7d22', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Course/Level1/Chapter1/Lesson4/18_RankMapPageTwo.vue"
export default component.exports